import { useEffect, useCallback, useMemo, useState } from "react";
import { Form, Icon, Label, Modal, Button } from "semantic-ui-react";

// UX
import CardTreatmentOrderUX from "./CardTreatmentOrderUX";
import ModTreatmentTemplateUX from "./ModTreatmentTemplateUX";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModError from "react-lib/appcon/common/ModInfo";
import ModAuthen from "react-lib/apps/common/ModAuthen";
import ModInfo from "react-lib/apps/common/ModInfo";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

// Interface
import { checkAllowEditOrder } from "./sequence/OrderTreatment";
import moment from "moment";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const CARD_TREATMENT_ORDER = "CardTreatmentOrder";

const MOD_TREATMENT_TEMPLATE = "ModTreatmentTemplate";

const MOD_CANCEL = "ModCancel";

const CardTreatmentOrder = (props: any) => {
  const intl = useIntl();
  const [selectedRowTemplate, setSelectedRowTemplate] = useState<any>(null);
  const [openModTemplate, setOpenModTemplate] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [openModAuthen, setOpenModAuthen] = useState<any>({
    open: false,
    isApproveOrder: false,
    id: "",
  });

  const [toBePerformMissing, setToBePerformMissing] = useState<boolean>(false);

  useEffect(() => {
    if (props.checkOutPerformDiv) {
      return;
    }
    let skipClear = false
    if (
      props.errorMessage?.OrderTreatment !== null &&
      props.errorMessage?.OrderTreatment !== undefined
    ) {
      setLoading(false);
      // props.runSequence({ sequence: "OrderTreatment", action: "clearMessage" });
      skipClear = true
    }
    if (
      props.successMessage?.OrderTreatment !== null &&
      props.successMessage?.OrderTreatment !== undefined
    ) {
      setLoading(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
        if (!skipClear) {
          props.runSequence({
            sequence: "OrderTreatment",
            action: "clearMessage",
          });
        }
      }, 2000);
    }
  }, [
    props.successMessage?.OrderTreatment,
    props.errorMessage?.OrderTreatment,
  ]);

  useEffect(() => {
    if (
      props.OrderTreatmentSequence?.alertMessage?.includes(
        "จำเป็นต้องระบุผู้ทำหัตถการ"
      )
    ) {
      setToBePerformMissing(true);
    }
  }, [props.OrderTreatmentSequence?.alertMessage]);

  useEffect(() => {
    if (props.successMessage?.["CardTreatmentOrder_confirm"]) {
      setOpenModAuthen({ open: false, isApproveOrder: false });
      props.setProp(`successMessage.${"CardTreatmentOrder_confirm"}`, null);
    }
  }, [props.successMessage?.["CardTreatmentOrder_confirm"]]);

  useEffect(() => {
    // console.log("CardTreatmentOrder start");
    props.runSequence({
      sequence: "OrderTreatment",
      restart: true,
      isNullEmr: props.isNullEmr,
      isOutPerformDiv: props.checkOutPerformDiv,
      editId: props.editId,
    });
    return () => {
      props.runSequence({ sequence: "OrderTreatment", clear: true });
    };
  }, []);

  useEffect(() => {
    if(props.isVerbalOrder && props.OrderTreatmentSequence?.selectedDoctorPendingTask) {
      props.setEditItem?.(props.OrderTreatmentSequence?.selectedDoctorPendingTask);
    }
  }, [props.OrderTreatmentSequence?.selectedDoctorPendingTask]);

  const setSelectedTreatment = useCallback(
    (name: string, id: number) => {
      console.log(" setSelectedTreatment name, id: ", name, id);

      props.setProp("OrderTreatmentSequence", {
        ...props.OrderTreatmentSequence,
        searchTreatment: name,
        searchTreatmentId: id,
      });
    },
    [props.OrderTreatmentSequence, props.setProp]
  );

  const mapTreatment = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_code,
      text: item.name_code,
    }));

  const handleDeleteOrder = useCallback(
    (product: any) => {
      let list = (props?.OrderTreatmentSequence?.treatmentList || []).filter(
        (item: any) => item.product !== product
      );

      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list,
        isNullEmr: props.isNullEmr,
      });
    },
    [props?.OrderTreatmentSequence?.treatmentList]
  );

  const icd9Search = useMemo(() => {
    return (props?.procedureItems || []).map((item: any) => ({
      ...item,
    }));
  }, [props?.procedureItems]);

  const dataTemplate = useMemo(() => {
    return (props?.treatmentTemplateList || []).map((item: any) => ({
      ...item,
      item_summary: (
        <div dangerouslySetInnerHTML={{ __html: item.item_summary }} />
      ),
    }));
  }, [props?.treatmentTemplateList]);

  const findDoctor = (order_by: number) => {
    let doctor = props.masterOptions?.doctor?.find(
      (data: any) => data.value === order_by
    );

    let doctorName = doctor?.text.split("(");
    return doctorName?.[0] || doctor;
  };

  const OrderTreatments = useMemo(() => {
    return (props?.OrderTreatmentSequence?.OrderTreatments || []).map(
      (item: any) => ({
        ...item,
        detail: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                textDecoration:
                  item.status === "CANCELED" ? "line-through" : "",
              }}
            >
              <div style={{ display: "flex" }}>
                <Label basic color="blue" style={{marginRight: "10px", height: "30px"}}>
                  {item.to_be_performer_name}
                </Label>
                <div dangerouslySetInnerHTML={{ __html: item.order_summary_detail }} />
              </div>
              {item?.order_detail && (
                <div style={{ color: "gray" }}>
                  <b>{intl.formatMessage({ id: "รายละเอียดเพิ่มเติม :" })}</b>
                  {item.order_detail}
                </div>
              )}
              {item?.equipment_detail && (
                <div style={{ color: "gray" }}>
                  <b>{intl.formatMessage({ id: "อุปกรณ์ :" })}</b>
                  {item.equipment_detail}
                </div>
              )}
            </div>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "50%",
                width: "1em",
                height: "1em",
                margin: "0px 5px",
                ...(!(item?.need_approve && item?.student_name) && {
                  display: "none",
                }),
              }}
            />
          </div>
        ),
        delete: ((props.isStudentUser && item.order_by !== item.approved_by) ||
          !props.isStudentUser) && (
          <div
            style={{ ...(item.status === "CANCELED" && { display: "none" }) }}
          >
            <DeleteColumn
              value={true}
              id={item.id}
              titleName={intl.formatMessage({ id: "ต้องการลบรายการหัตการ นี้ใช่หรือไม่" })}
              icon="trash alternat"
              circular={true}
              size="mini"
              titleColor="blue"
              disabled={item.status === "PERFORMED"}
              onConfirm={() => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "cancel",
                  card: MOD_CANCEL,
                  item: item,
                });
              }}
              languageUX={props.languageUX}
            />
          </div>
        ),
        edit: checkAllowEditOrder({
          ...item,
          isStudentUser: props.isStudentUser,
        }) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              size="mini"
              circular
              icon="edit"
              color="blue"
              disabled={item.status === "PERFORMED"}
              onClick={() => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "edit",
                  item: item,
                });
                setDataChanged(false);
                if(props.isVerbalOrder) {
                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "find_doctor_pending_task",
                    order: item,
                  });
                } else {
                  props.setProp(
                    "OrderTreatmentSequence.selectedDoctorPendingTask",
                    null
                  );
                }
              }}
            />
          </div>
        ),
        careprovider: item?.order_status !== "CANCEL" && (
          <div>
            {item?.order_by === item?.approved_by ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item?.approved_by_name}
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item?.order_by_name || findDoctor(item?.order_by)}
                </div>
                {item?.need_approve && item?.student_name ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <ButtonLoadCheck
                      // function
                      id="btn-confirmMedOrder"
                      setProp={props.setProp}
                      onClick={() => {
                        props.setProp(
                          "OrderCentralLabSequence.orderId",
                          item?.id
                        );

                      let fullNameSplit = props.django?.user?.full_name.split(" ");

                      if (
                        props.selectedEmr?.doctor_name ===
                          `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
                        item?.student_name
                      ) {
                        handleSaveApprove({
                          approved_by_username: "",
                          approved_by_password: "",
                          id: item?.id,
                        });
                      } else {
                        setOpenModAuthen({
                          open: true,
                          isApproveOrder: true,
                          id: item.id,
                        });
                      }
                    }}
                    // data
                    paramKey={`${CARD_TREATMENT_ORDER}_CONFIRM_${item?.id}`}
                    buttonLoadCheck={
                      props.buttonLoadCheck?.[`${CARD_TREATMENT_ORDER}_CONFIRM_${item?.id}`]
                    }
                    // config
                    color={"green"}
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/static/images/drugOrder/doctor_approve.png"
                          style={{ width: "20px", marginRight: "7px" }}
                        ></img>
                        <label>APPROVE</label>
                      </div>
                    }
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              ) : (
                item?.student_name && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    >
                      <img
                        src="/static/images/drugOrder/approve_check.png"
                        style={{ width: "10px 0px", marginRight: "7px" }}
                      ></img>
                      <label style={{ color: "green" }}>APPROVE</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "gray",
                      }}
                    >
                      ตรวจสอบโดย
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      {item?.approved_by_name}
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ),
    }));
  }, [props?.OrderTreatmentSequence?.OrderTreatments]);

  const treatmentList = useMemo(() => {
    return (props.OrderTreatmentSequence?.treatmentList || []).map(
      (item: any, index: number) => ({
        ...item,
        product: item.name_code,
        quantity: item.quantity,
        claim: props?.claimOptions?.filter(
          (acc: any) => acc.value === item.eligibility_type
        )?.[0]?.text,
        status: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}>
              {" "}
              {props?.treatmentOrderList?.item?.status_name}
            </div>
            <div style={{ flex: "1" }} />
            <div
              style={{
                marginRight: "5px",
                display: props?.treatmentOrderList?.item
                  ? props?.treatmentOrderList?.item?.status_name ===
                    "รอทำหัตถการ"
                    ? ""
                    : "none"
                  : "",
              }}
            >
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={() => {
                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeTreatmentList",
                    value: item,
                    index: index,
                  });

                  setDataChanged(true);
                }}
              />
            </div>
          </div>
        ),
        del: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="red"
              icon="minus"
              onClick={(e) => handleDeleteOrder(item.product)}
              size={"mini"}
            ></Button>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence]);

  const diagnosisListSecondary = useMemo(() => {
    return (props.OrderTreatmentSequence?.diagnosisListSecondary || []).map(
      (item: any, index: number) => ({
        ...item,
        icd9cm_med_term: item.medical_description || item.icd9cm_med_term,
        icd9cm_code: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}> {item.icd9cm_code}</div>
            <div style={{ flex: "1" }} />
            <div style={{ marginRight: "5px" }}>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });

                  setDataChanged(true);
                }}
              />
            </div>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence?.diagnosisListSecondary]);

  const diagnosisListPrincipal = useMemo(() => {
    return (props.OrderTreatmentSequence?.diagnosisListPrincipal || []).map(
      (item: any, index: number) => ({
        ...item,
        icd9cm_med_term: item.medical_description || item.icd9cm_med_term,
        icd9cm_code: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}> {item.icd9cm_code}</div>
            <div style={{ flex: "1" }} />
            <div style={{ marginRight: "5px" }}>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={() => {
                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });

                  setDataChanged(true);
                }}
              />
            </div>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence]);

  const handleCloseModTemplate = () => {
    props.setProp(`errorMessage.${MOD_TREATMENT_TEMPLATE}`, null);

    setOpenModTemplate(false);
    setSelectedRowTemplate(null);
  };

  const handleCloseModEvaluate = () => {
    props.setProp("OrderTreatmentSequence.alertMessage", "");
  };

  const addTreatment = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    if (
      (props.OrderTreatmentSequence?.treatmentList?.find(
        (item: any) =>
          item.id !== props.OrderTreatmentSequence?.searchTreatmentId
      ) ||
        !props.OrderTreatmentSequence?.treatmentList?.length) &&
      props.OrderTreatmentSequence?.searchTreatmentId &&
      props.OrderTreatmentSequence?.searchTreatment &&
      props.OrderTreatmentSequence?.treatmentQuantity &&
      props.OrderTreatmentSequence?.eligibilityType
    ) {
      let prepare = {
        product: props.OrderTreatmentSequence?.searchTreatmentId,
        name_code: props.OrderTreatmentSequence?.searchTreatment,
        quantity: props.OrderTreatmentSequence?.treatmentQuantity,
        eligibility_type: props.OrderTreatmentSequence?.eligibilityType,
        claim: props.OrderTreatmentSequence?.claim,
      };
      let list = [...(props?.OrderTreatmentSequence?.treatmentList || [])];

      list.push(prepare);

      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list,
        isNullEmr: props.isNullEmr,
        encounterId: props.encounterId || null,
      });

      setDataChanged(true);
    }
  };

  const onSearchTextChange = (key: string) => async (e: any, v: any) => {
    await props.setProp(`OrderTreatmentSequence.${key}`, v.value);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "searchPrincipal",
      name: v.name,
      value: v.value,
    });
  };

  const onSearchSecondaryChange = (key: string) => async (e: any, v: any) => {
    await props.setProp(`OrderTreatmentSequence.${key}`, v.value);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "searchSecondary",
      name: v.name,
      value: v.value,
    });
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`treatmentOrderList.${key}`, v.value);
    setDataChanged(true);
  };

  const handleCloseMessage = () => {
    props.setProp(`successMessage.${CARD_TREATMENT_ORDER}`, null);
    props.setProp(`errorMessage.${CARD_TREATMENT_ORDER}`, null);
  };

  const handleCloseModInfo = () => {
    props.setProp(`OrderTreatmentSequence.isOpenError`, false);
  };

  const handleCheckPreOrder = async (e: any, v: any) => {
    // console.log("Checkbox", e, v);
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    await props.setProp(`OrderTreatmentSequence.preOrderCheck`, value);

    if (v.checked === false) {
      props.setProp(`OrderTreatmentSequence.preOrderDate`, null);
    }
  };

  const onChangePreOrderDate = (key: string) => (date: any, v: any) => {
    props.setProp(`OrderTreatmentSequence.${key}`, v?.value ? v.value : date);
  };

  const handleSave = () => {
    const orderBy = props.OrderTreatmentSequence?.orderDoctorId;

    if (
      props.isAppointment &&
      !orderBy
    ) {
      props.setProp(`OrderTreatmentSequence.alertMessage`, "กรุณาระบุแพทย์ผู้สั่ง");

      return;
    }

    setLoading(true);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "save",
      isNullEmr: props.isNullEmr,
      card: CARD_TREATMENT_ORDER,
      isOutPerformDiv: props.checkOutPerformDiv,
      onSuccess: props.onSuccess,
      editId: props.editId,
      encounterId: props.encounterId,
      orderStatus: props?.isAppointment ? 1 : 2,
      dataChanged: dataChanged,
      isAppointment: props?.isAppointment,
      order_by: props.OrderTreatmentSequence?.orderDoctorId,
    });

    setDataChanged(false);
  };

  const handleAddTemplate = (item: any) => {
    if (!item) {
      return props.setProp(
        `errorMessage.${MOD_TREATMENT_TEMPLATE}`,
        "กรุณาเลือกรายการหัตถการ"
      );
    }

    if (!props.checkOutPerformDiv) {
      props.runSequence({
        sequence: "OrderTreatment",
        action: "selectTemplate",
        card: CARD_TREATMENT_ORDER,
        value: item,
        isNullEmr: props.isNullEmr,
      });
    } else {
      let templateList = item.template_items.map((item: any) => ({
        product: item.product,
        name_code: item.name_code,
        quantity: item.quantity,
        eligibility_type: props.claimOptions?.[0]?.value || null,
        claim: props.claimOptions?.[0]?.text || null,
      }));

      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list: [
          ...(props?.OrderTreatmentSequence?.treatmentList || []),
          ...templateList,
        ],
        isNullEmr: props.isNullEmr,
      });
    }

    handleCloseModTemplate();
  };

  const handleSaveApprove = (params: any) => {
    props.runSequence({
      sequence: "OrderTreatment",
      action: "confirm",
      card: CARD_TREATMENT_ORDER,
      approved_by_username: params.approved_by_username,
      approved_by_password: params.approved_by_password,
      id: params.id,
      dataChanged: dataChanged,
    });

    setDataChanged(false);
  };

  const handleSelectedOrderDoctor = (value: any) => {
    props.setProp("OrderTreatmentSequence", {
      ...props.OrderTreatmentSequence,
      orderDoctorId: value || null,
      orderDoctorNameCode: "",
    });
  };

  const handleConfirmOrder = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    let fullNameSplit = props.django?.user?.full_name.split(" ");
    setToBePerformMissing(false);
    if (
      props.selectedEmr?.doctor_name ===
        `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
      props.treatmentOrderList?.item?.student_name
    ) {
      handleSaveApprove({
        approved_by_username: "",
        approved_by_password: "",
        id: props.treatmentOrderList?.item?.id,
      });
    } else if (
      (props.treatmentOrderList?.item &&
        props.treatmentOrderList?.item?.order_by === props.treatmentOrderList?.item?.student) ||
      props.isStudentUser
    ) {
      setOpenModAuthen({
        open: true,
        isApproveOrder: true,
        id: props.treatmentOrderList?.item?.id,
      });
    } else {
      handleSave();
    }
  };

  console.log("errorMessage", props?.errorMessage?.OrderTreatment)

  return (
    <>
      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      <div style={{ height: "100%" }}>
        {props?.OrderTreatmentSequence?.alertMessage && (
          <ModInfo
            id="CardTreatmentOrder-ModInfo-AlertMessage"
            open={true}
            titleColor={
              props?.OrderTreatmentSequence?.alertMessage?.includes("จำเป็นต้องระบุผู้ทำหัตถการ")
                ? "red"
                : "blue"
            }
            titleName={props?.OrderTreatmentSequence?.alertMessage}
            onApprove={handleCloseModEvaluate}
          />
        )}
        <CardTreatmentOrderUX
          setProp={props.setProp}
          onEvent={props.onEvent}
          hidePreOrder={
            // * ซ่อนตามเงื่อนไข หรือ ซ่อนโดย property
            ["หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(props.divisionType || "") ||
            props.hidePreOrder
          }
          hideSaveButton={props.hideSaveButton}
          hidePrincipalDiagnosis={props.hidePrincipalDiagnosis}
          hideSecondaryDiagnosis={props.hideSecondaryDiagnosis}
          checkOutPerformDiv={props.checkOutPerformDiv}
          divisionOpdOptions={props.divisionOpdOptions}
          titleName={props.titleName}
          OrderTreatments={OrderTreatments}
          toBePerformMissing={toBePerformMissing}
          // treatmentQuantity
          treatmentQuantity={props.OrderTreatmentSequence?.treatmentQuantity}
          onChangeTreatmentQuantity={(e: any, { value }: any) => {
            props.setProp("OrderTreatmentSequence.treatmentQuantity", value);
          }}
          // eligibilityType
          claimOptions={props.claimOptions || []}
          eligibilityType={props.OrderTreatmentSequence?.eligibilityType}
          onChangeEligibilityType={(e: any, v: any) => {
            let options = v.options.filter((item: any) => item.value === v.value);

            props.setProp("OrderTreatmentSequence", {
              ...props.OrderTreatmentSequence,
              eligibilityType: v.value,
              claim: options?.[0]?.text,
            });
          }}
          searchTreatmentListBox={
            <SearchBoxDropdown
              type={"Treatment"}
              style={{ width: "100%" }}
              fluid={true}
              startSearchLength={3}
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              selectedItem={props.OrderTreatmentSequence?.searchTreatment}
              setSelectedItem={setSelectedTreatment}
              mapOptions={mapTreatment}
            />
          }
          addTreatment={addTreatment}
          treatmentList={treatmentList}
          treatmentOrder={props?.OrderTreatmentSequence?.treatmentOrder}
          isEdit={!!props.editId}
          //Save
          save={completed ? <Icon className="check"></Icon> : "SAVE"}
          loading={loading}
          orderDetail={props?.treatmentOrderList?.orderDetail || ""}
          toBePerformer={props?.treatmentOrderList?.toBePerformer || ""}
          equipmentDetail={props?.treatmentOrderList?.equipmentDetail || ""}
          ErrorMessage={<ErrorMessage error={props?.errorMessage?.OrderTreatment} />}
          options={[
            { key: 1, value: "DOCTOR", text: "Doctor" },
            { key: 2, value: "NURSE", text: "Nurse" },
            { key: 3, value: "OTHER", text: "Other" },
          ]}
          handleChangeValue={handleChangeValue}
          onSave={handleSave}
          // Primary
          // Med Term
          medTermPrincipal={props.OrderTreatmentSequence?.medTermPrincipal || ""}
          loadingmedTermPrincipal={props.OrderTreatmentSequence?.loadingICD9CMTermPrincipal}
          // ICD9CM Term
          ICD9CMTermPrincipal={props.OrderTreatmentSequence?.ICD9CMTermPrincipal || ""}
          loadingICD9CMTermPrincipal={props.OrderTreatmentSequence?.loadingICD9CMTermPrincipal}
          // ICD9CM Code
          ICD9CMCodeprincipal={props.OrderTreatmentSequence?.ICD9CMCodeprincipal || ""}
          loadingICD9CMCodeprincipal={props.OrderTreatmentSequence?.loadingICD9CMCodeprincipal}
          //Search icd9
          icd9Search={icd9Search}
          icd9RowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: () => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "select",
                  card: CARD_TREATMENT_ORDER,
                  value: rowInfo.original,
                });
              },
            };
          }}
          showSearchResult={props?.OrderTreatmentSequence?.showSearchResult}
          onSearchTextChange={onSearchTextChange}
          //Add
          onAddPrincipal={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action:
                props.OrderTreatmentSequence?.medTermPrincipal ||
                props.OrderTreatmentSequence?.ICD9CMTermPrincipa ||
                props.OrderTreatmentSequence?.ICD9CMCodeprincipal
                  ? "add"
                  : null,
              card: CARD_TREATMENT_ORDER,
            });

            setDataChanged(true);
          }}
          diagnosisListPrincipal={diagnosisListPrincipal}
          //pre order
          preOrderCheck={props?.OrderTreatmentSequence?.preOrderCheck || false}
          preOrderDate={props.OrderTreatmentSequence?.preOrderDate}
          handleCheckPreOrder={handleCheckPreOrder}
          onChangePreOrderDate={onChangePreOrderDate}
          // Secondary
          // Med Term
          medTermSecondary={props.OrderTreatmentSequence?.medTermSecondary || ""}
          loadingMedTermSecondary={props.OrderTreatmentSequence?.loadingMedTermSecondary}
          // ICD9CM Term
          ICD9MTermSecondary={props.OrderTreatmentSequence?.ICD9MTermSecondary || ""}
          loadingICD9MTermSecondary={props.OrderTreatmentSequence?.loadingICD9MTermSecondary}
          // ICD9CM Code
          ICD9MCodeSecondary={props.OrderTreatmentSequence?.ICD9MCodeSecondary || ""}
          loadingICD9MCodeSecondary={props.OrderTreatmentSequence?.loadingICD9MCodeSecondary}
          onSearchSecondaryChange={onSearchSecondaryChange}
          showSearchResultSecondary={props?.OrderTreatmentSequence?.showSearchResultSecondary}
          secondarySearch={props?.secondaryItems}
          secondaryRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: () => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "selectSecondary",
                  card: CARD_TREATMENT_ORDER,
                  value: rowInfo.original,
                });
              },
            };
          }}
          orderRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              className:
                !!props.treatmentOrderList?.item?.id &&
                rowInfo?.original?.id === props.treatmentOrderList?.item?.id
                  ? "blueSelectedRow"
                  : "",
            };
          }}
          //Add
          onAddSecondary={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action:
                props.OrderTreatmentSequence?.medTermSecondary ||
                props.OrderTreatmentSequence?.ICD9MTermSecondary ||
                props.OrderTreatmentSequence?.ICD9MCodeSecondary
                  ? "addSecondary"
                  : null,
              card: CARD_TREATMENT_ORDER,
            });

            setDataChanged(true);
          }}
          diagnosisListSecondary={diagnosisListSecondary}
          onSearch={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action: "search",
              card: CARD_TREATMENT_ORDER,
              isNullEmr: props.isNullEmr,
            });
            setOpenModTemplate(true);
          }}
          // onSearch={(e: any) =>
          //   props.runSequence({
          //     sequence: "OrderTreatment",
          //     action: "search",
          //   })
          // }
          buttonSaveOrder={
            <ButtonLoadCheck
              // function
              id="btn-confirmMedOrder"
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={`${CARD_TREATMENT_ORDER}_save`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_TREATMENT_ORDER}_save`]}
              // config
              disabled={
                props.treatmentOrderList?.item && props.isStudentUser && dataChanged === false
              }
              color="blue"
              title="SAVE ORDER"
              style={{
                margin: "0px 5px",
                ...(!props.isStudentUser &&
                  props.treatmentOrderList?.item?.id !== null && {
                    display: "none",
                  }),
              }}
            />
          }
          buttonConfirmOrder={
            <ButtonLoadCheck
              // function
              id="btn-confirmMedOrder"
              setProp={props.setProp}
              onClick={handleConfirmOrder}
              // data
              paramKey={
                (props.treatmentOrderList?.item &&
                  props.treatmentOrderList?.item?.order_by ===
                    props.treatmentOrderList?.item?.student) ||
                props.isStudentUser
                  ? `${CARD_TREATMENT_ORDER}_confirm`
                  : `${CARD_TREATMENT_ORDER}_save`
              }
              buttonLoadCheck={
                (props.treatmentOrderList?.item &&
                  props.treatmentOrderList?.item?.order_by ===
                    props.treatmentOrderList?.item?.student) ||
                props.isStudentUser
                  ? props.buttonLoadCheck?.[`${CARD_TREATMENT_ORDER}_confirm`]
                  : props.buttonLoadCheck?.[`${CARD_TREATMENT_ORDER}_save`]
              }
              // config
              // disabled={
              //   props.treatmentOrderList?.item &&
              //   props.isStudentUser &&
              //   dataChanged === false
              // }
              color="green"
              title="CONFIRM ORDER"
              style={{
                margin: "0px 5px",
              }}
            />
          }
          orderByBox={
            <Form style={{ display: props.isAppointment ? "" : "none" }}>
              <Form.Group inline>
                <Form.Field style={{ width: "80px" }} required>
                  <label>{intl.formatMessage({ id: "แพทย์ผู้สั่ง" })}</label>
                </Form.Field>
                <Form.Field style={{ width: "300px" }}>
                  <SearchBoxWithKey
                    id="TrtOrderBy"
                    // callback
                    onEvent={props.onEvent}
                    setSelected={handleSelectedOrderDoctor}
                    // data
                    type="Doctor"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={props.OrderTreatmentSequence?.orderDoctorId || null}
                    defaultText={props.OrderTreatmentSequence?.orderDoctorNameCode}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          }
          minDate={formatDateToStringBe(moment())}
          languageUX={props.languageUX}
        />
      </div>

      {/* <ModInfo
        open={!!props.successMessage?.[CARD_TREATMENT_ORDER]}
        titleColor="green"
        titleName={intl.formatMessage({ id: "บันทึกข้อมูลสำเร็จ" })}
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      ></ModInfo>

      <ModInfo
        open={props?.OrderTreatmentSequence?.isOpenError}
        titleColor="red"
        titleName={intl.formatMessage({ id: "บันทึกข้อมูลไม่สำเร็จ" })}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo> */}

      <Modal open={openModTemplate} size="large" onClose={handleCloseModTemplate}>
        <ModTreatmentTemplateUX
          treatmentTemplate={dataTemplate}
          // callback
          rowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              style: {
                backgroundColor:
                  rowInfo?.original?.id && rowInfo?.original?.id === selectedRowTemplate?.id
                    ? "#cccccc"
                    : "white",
              },
              onClick: () => setSelectedRowTemplate(rowInfo.original),
              onDoubleClick: () => handleAddTemplate(selectedRowTemplate),
            };
          }}
          onAddTemplate={() => handleAddTemplate(selectedRowTemplate)}
          // Element
          errorMessage={<ErrorMessage error={props?.errorMessage?.[MOD_TREATMENT_TEMPLATE]} />}
        />
      </Modal>

      <ModAuthen
        titleName={intl.formatMessage({ id: "ยืนยันการตรวจสอบ" })}
        titleColor={"blue"}
        open={openModAuthen?.open}
        onDeny={() => setOpenModAuthen({ open: false, isApproveOrder: false, id: "" })}
        onApprove={({ username, password }: any) => {
          // save
          handleSaveApprove({
            approved_by_username: username,
            approved_by_password: password,
            id: openModAuthen?.id,
          });
          // setOpenModAuthen({ open: false, isApproveOrder: false, id: "" });
        }}
      />

      <ModError
        open={props.errorMessage?.["CardTreatmentOrder_confirm"]}
        titleColor="red"
        onApprove={() => {
          props.setProp(`errorMessage.${"CardTreatmentOrder_confirm"}`, null);
        }}
        alertText={props.errorMessage?.["CardTreatmentOrder_confirm"]?.error?.approved_by?.[0]}
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </>
  );
};

export default CardTreatmentOrder;
